import KexFacet from 'Models/Search/KexFacet.interface';
import { useEffect, useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { useFilterData } from './FilterProvider';
import KexTerm from 'Models/Search/KexTerm.interface';
import Button from 'Atoms/Buttons/Button';
import { getUrlParameter, setUrlParameter } from 'Shared/Common/Helpers';
import { SearchTypes } from 'Pages/SearchPage/Search';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

export const PRODUCT_CLASS = 'ProductClass';
export const NEW_MOTORCYCLE_CODE = '10';
export const USED_MOTORCYCLE_CODE = '20';

type PropTypes = {
  facets?: KexFacet[];
  query: string;
  contentResultAmount: number;
  setActiveTab: (value: SearchTypes) => void;
  activeTab: SearchTypes;
};

function ContentTypeContainer({
  facets,
  query,
  contentResultAmount,
  setActiveTab,
  activeTab,
}: PropTypes) {
  const [{ multiSelectFilters }, dispatch] = useFilterData();
  const [contentTypes, setContentTypes] = useState<Map<string, KexTerm[]>>(
    new Map()
  );
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);
  const {
    searchLabels: {
      motorcycles,
      accessories,
      clothes,
      spareparts,
      pages,
      youSearchedFor,
    },
  } = useTranslationData();

  const list = [
    {
      contentType: motorcycles,
      termName: NEW_MOTORCYCLE_CODE,
      secondTermName: USED_MOTORCYCLE_CODE,
    },
    { contentType: accessories, termName: '40' },
    { contentType: clothes, termName: '50' },
    { contentType: spareparts, termName: '30' },
  ];

  useEffect(() => {
    setActiveTab(
      (getUrlParameter('activeTab') as SearchTypes) || SearchTypes.Products
    );
  }, [setActiveTab]);

  useEffect(() => {
    if (!facets) {
      const newContentTypes = new Map<string, KexTerm[]>(
        list.map((k) => [
          k.contentType,
          [
            {
              count: 0,
              term: k.contentType,
              userFriendlyName: '',
            },
          ],
        ])
      );

      newContentTypes.set(pages, [
        {
          count: contentResultAmount,
          term: pages,
          userFriendlyName: '',
        },
      ]);
      setContentTypes(newContentTypes);
      return;
    }

    const foundEntries = facets
      ?.filter((facet) => facet.name === PRODUCT_CLASS)
      .flatMap((facet) =>
        list.map((entry): [string, KexTerm[]] => {
          const firstFound = facet.terms.find(
            (item) => item.term === entry.termName && item.count
          );
          const secondFound = facet.terms.find(
            (item) => item.term === entry.secondTermName && item.count
          );

          return [
            entry.contentType,
            [firstFound, secondFound].map((item) => ({
              term: item?.term || '',
              count: item?.count || 0,
              userFriendlyName: '',
            })),
          ];
        })
      );
    const newContentTypes = new Map(foundEntries);
    newContentTypes.set(pages, [
      {
        count: contentResultAmount,
        term: pages,
        userFriendlyName: '',
      },
    ]);
    setContentTypes(newContentTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facets, contentResultAmount, pages]);

  const isChecked = (key: string) => {
    const obj = contentTypes.get(key);

    if (key === motorcycles) {
      const ret =
        !!multiSelectFilters.get(PRODUCT_CLASS)?.has(NEW_MOTORCYCLE_CODE) ||
        !!multiSelectFilters.get(PRODUCT_CLASS)?.has(USED_MOTORCYCLE_CODE);
      return ret;
    } else {
      if (obj && obj[0]?.term) {
        return !!multiSelectFilters.get(PRODUCT_CLASS)?.has(obj[0]?.term);
      }
    }
    return false;
  };

  const clearUrl = () => {
    const newUrl = `${window.location.pathname}`.concat(
      query !== '' ? `?query=${query} ` : ''
    );
    window.history.replaceState(null, '', newUrl);
  };

  const changeContentType = (key: string) => {
    const obj = contentTypes.get(key);
    clearUrl();
    dispatch({ type: 'onClear' });
    if (key !== pages) {
      setActiveTab(SearchTypes.Products);

      if (obj && obj[0]?.term) {
        let newFilter = new Map();
        const set = new Set<string>();

        if (!isChecked(key)) {
          set.add(obj[0]?.term);
          key === motorcycles && set.add(obj[1]?.term || USED_MOTORCYCLE_CODE);
        }
        newFilter.set(PRODUCT_CLASS, set);

        dispatch({
          type: 'setMultiSelectFilters',
          selectFilters: newFilter,
        });
        Array.from(newFilter).forEach(([key, values]) => {
          setUrlParameter('filter::' + key, Array.from(values).join(';'));
        });
      }
    } else {
      if (activeTab === SearchTypes.Products) {
        setUrlParameter('activeTab', SearchTypes.Content);
        setActiveTab(SearchTypes.Content);
      } else {
        setActiveTab(SearchTypes.Products);
        setUrlParameter('activeTab', SearchTypes.Products);
      }
    }
  };

  const calculateCount = (item: [string, KexTerm[]]) => {
    let newCount = 0;
    if (!!item[1][0]?.count) newCount += item[1][0]?.count;
    if (!!item[1][1]?.count) newCount += item[1][1]?.count;
    return newCount;
  };

  return (
    <ContentContainer>
      <div style={{ maxWidth: '850px', margin: 'auto' }}>
        {!isMobile && (
          <YouSearchedForWrapper>
            <YouSearchedFor> {youSearchedFor} </YouSearchedFor>
            <Query>{query}</Query>
          </YouSearchedForWrapper>
        )}

        <Wrapper>
          {Array.from(contentTypes).map((item) => {
            return (
              <Button
                key={item[0]}
                type={
                  isChecked(item[0]) ||
                  (item[0] === pages
                    ? activeTab === SearchTypes.Content
                    : false)
                    ? 'secondary'
                    : 'secondaryDark'
                }
                css={{
                  mx: 0.5,
                  flexGrow: 1,
                  minW: 34,
                }}
                disabled={
                  calculateCount(item) === 0 ||
                  (item[0] === pages ? contentResultAmount < 1 : false)
                }
                onClick={() => {
                  changeContentType(item[0]);
                }}
              >
                {item[0]}
              </Button>
            );
          })}
        </Wrapper>
      </div>
    </ContentContainer>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  mx: 'auto',
  mt: 12,
  '@mediaMaxLarge': {
    overflow: 'scroll',
    w: '100vw',
    px: 4,
    left: '50%',
    right: '50%',
    maxWidth: '100vw',
    position: 'relative',
    marginLeft: `-50vw`,
    marginRight: '-50vw',
  },
  '@mediaMinLarge': {
    justifyContent: 'center',
  },
});

const YouSearchedForWrapper = styled('div', {
  ml: 0.5,
});

const YouSearchedFor = styled('div', {
  fs: 6,
  textAlign: 'start',
  textTransform: 'uppercase',
  color: '$searchPageTextPrimary',
  pb: 2,
  lineHeight: '$lh133',
  fontFamily: '$fontPrimary400',
});

const Query = styled('p', {
  fs: 16,
  fontFamily: '$fontPrimary400',
  textTransform: 'uppercase',
  lineHeight: '$lh1125',
  textAlign: 'start',
  color: '$searchPageTextPrimary',
});

export default ContentTypeContainer;
