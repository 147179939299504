import Checkbox from 'Atoms/Checkbox/Checkbox';
import { FiltersIcon } from 'Atoms/Icons';
import KexFacet from 'Models/Search/KexFacet.interface';
import SearchResultProductModel from 'Models/Search/SearchResultProductModel.interface';
import { useMemo, useState } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentTypeContainer, { PRODUCT_CLASS } from './ContentTypeContainer';
import FilterItem from './FilterItem';
import FilterModal from './FilterModal';
import { useFilterData } from './FilterProvider';
import { ProductType } from 'Enums/ProductType.enum';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { SearchTypes } from '../../Pages/SearchPage/Search';
import CategoryListContainer from '../../Pages/CategoryPage/CategoryListContainer';
import SorterContainer from './SorterContainer';
import CategorySubLinksCard from 'Organisms/CategorySubLinksCard/CategorySubLinksCard';
import { CSS } from '@stitches/react';
import {
  NEW_MOTORCYCLE_CODE,
  USED_MOTORCYCLE_CODE,
} from './ContentTypeContainer';
import { useHeaderData } from '../Header/Header';

type Condition = typeof NEW_MOTORCYCLE_CODE | typeof USED_MOTORCYCLE_CODE;

type PropTypes = {
  results: {
    facets?: KexFacet[];
    productResults?: SearchResultProductModel;
  };
  query?: string;
  counterText: string;
  contentResultAmount?: number;
  setActiveTab?: (value: SearchTypes) => void;
  activeTab?: SearchTypes;
  css?: CSS;
  includesMotorcycles?: boolean;
  pageTitle?: string;
};

function FilterBar({
  results: { facets, productResults },
  query,
  counterText,
  contentResultAmount,
  setActiveTab,
  activeTab,
  css,
  includesMotorcycles,
  pageTitle,
}: PropTypes) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [{ multiSelectFilters }, dispatch] = useFilterData();
  const header = useHeaderData();

  const { breadcrumb } = useCurrentPage();
  const { pageType, pageId } = useCurrentPage();

  const subCategories = useMemo(() => {
    const found =
      header?.commerceLinks &&
      header?.commerceLinks.find((item) => item.id === pageId);
    return found?.subLinks;
  }, [header, pageId]);

  const {
    searchLabels: { filters, conditionNew, conditionUsed },
  } = useTranslationData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function handleConditionQuickFilter(condition: Condition) {
    dispatch({
      type: 'setMultiSelectFilter',
      key: PRODUCT_CLASS,
      value: condition,
      checked: !multiSelectFilters.get(PRODUCT_CLASS)?.has(condition),
    });
  }

  const renderChips = pageType === 'CategoryPage' && facets;

  const isMotorcycle = !!productResults?.items?.find(
    (p) => p.productType === ProductType.MOTORCYCLES
  );

  return (
    <>
      {setActiveTab && activeTab && pageType === 'SearchPage' && (
        <ContentTypeContainer
          facets={facets}
          query={query ? query : ''}
          contentResultAmount={contentResultAmount || 0}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      )}
      {renderChips && (
        <CategoryListContainer
          facets={facets}
          productResults={productResults}
          pageTitle={pageTitle}
        />
      )}
      {breadcrumb.length < 2 && !isMotorcycle && !!subCategories?.length && (
        <SubCategoriesContainer>
          {subCategories?.map((item) => (
            <CategorySubLinksCard
              key={item.linkId}
              text={item.text}
              imageHref={item.navigationImage?.src}
              href={item.href}
            />
          ))}
        </SubCategoriesContainer>
      )}
      {(setActiveTab && activeTab
        ? activeTab === SearchTypes.Products
        : true) &&
        facets && (
          <Container css={css}>
            {facets &&
              !isMobile &&
              facets.map(
                (item: KexFacet, index: number) =>
                  index < 2 && (
                    <FilterItem
                      key={item.name + index}
                      item={item}
                      query={query ? query : ''}
                    />
                  )
              )}
            <DropDownContainer onClick={toggleModal}>
              <DropDownHeader>
                {filters}
                <FiltersIcon color="secondary" size="m" css={{ ml: 4 }} />
              </DropDownHeader>
            </DropDownContainer>
            {!isMobile && includesMotorcycles && (
              <CheckBoxContainer>
                <Checkbox
                  isChecked={
                    multiSelectFilters
                      .get(PRODUCT_CLASS)
                      ?.has(USED_MOTORCYCLE_CODE) ?? false
                  }
                  text={conditionUsed}
                  onCheck={() =>
                    handleConditionQuickFilter(USED_MOTORCYCLE_CODE)
                  }
                  css={{ ml: 8 }}
                />
                <Checkbox
                  isChecked={
                    multiSelectFilters
                      .get(PRODUCT_CLASS)
                      ?.has(NEW_MOTORCYCLE_CODE) ?? false
                  }
                  text={conditionNew}
                  onCheck={() =>
                    handleConditionQuickFilter(NEW_MOTORCYCLE_CODE)
                  }
                  css={{ ml: 8 }}
                />
              </CheckBoxContainer>
            )}
            <RightContainer>
              {!isMobile && <CounterText>{counterText}</CounterText>}
              {productResults && (
                <SorterContainer productResult={productResults} />
              )}
            </RightContainer>
          </Container>
        )}
      {facets && !!facets && (
        <FilterModal
          showModal={modalOpen}
          toggle={toggleModal}
          facets={facets}
          query={query ? query : ''}
          productHits={productResults?.availableItems || 0}
        />
      )}
    </>
  );
}

export default FilterBar;

const Container = styled('div', {
  display: 'flex',
  w: '100%',
  alignItems: 'center',
  color: '$textPrimary',
  backgroundColor: '$contentResultBackgroundPrimary',
  pt: 6,
  pb: 0,
  flexWrap: 'wrap',
  '@mediaMinLarge': {
    pt: 12,
    pb: 0,
  },
});

const SubCategoriesContainer = styled('div', {
  mt: 9,
});

const DropDownContainer = styled('button', {
  w: 'fit-content',
  position: 'relative',
  h: 'auto',
  display: 'block',
  textAlign: 'left',
});

const CheckBoxContainer = styled('div', {
  display: 'flex',
});

const DropDownHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  py: 2,
  px: 4,
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$dropdownHeaderBackground',
  transition: `background-color ${timings.threeTenths} ${animation.timingFn}`,
  whiteSpace: 'nowrap',
  variants: {
    isOpen: {
      true: {
        backgroundColor: '$primary2',
      },
    },
  },
  color: '$dropdownText',
  fontFamily: '$fontPrimary400',
  wordSpacings: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$lhn1',
  fs: 9,
  '@mediaMinMedium': {
    fs: 8,
  },
  wordSpacing: '$wordSpacings$fontPrimary',
});

const CounterText = styled('p', {
  fs: 6,
  textAlign: 'right',
  flexGrow: 1,
  mr: 8,
  color: '$primaryLight2',
});

const RightContainer = styled('div', {
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  maxWidth: '50%',
});
