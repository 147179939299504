import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';

type PropType = {
  isChecked: boolean;
  onCheck?: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  amount: number;
};

function FilterChips({ isChecked, onCheck, text, amount }: PropType) {
  return (
    <FilterChipsWrapper onClick={onCheck} isChecked={isChecked}>
      <Label>{`${text} (${amount})`}</Label>
    </FilterChipsWrapper>
  );
}

const FilterChipsWrapper = styled('button', {
  width: 'fit-content',
  boxShadow: '0 0 0 1px $colors$filterChipsBorder',
  color: '$filterChipsColor',
  display: 'flex',
  flexShrink: 0,
  br: 10,
  py: 2.5,
  px: 4,
  height: 'auto',
  '&:hover': {
    '@mediaMinLarge': {
      boxShadow: '0 0 0 1px $colors$filterChipsColor',
    },
  },
  cursor: 'pointer',
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  variants: {
    isChecked: {
      true: {
        color: '$JE68GreenPrimary',
        boxShadow: '0 0 0 2px $colors$JE68GreenPrimary',
        '@mediaMinLarge': {
          '&:hover': {
            boxShadow: '0 0 0 2px $colors$filterChipsColor',
          },
        },
      },
    },
  },
});

const Label = styled('label', {
  cursor: 'pointer',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  lineHeight: '$lh125',
  ls: '$ls1',
  fs: 6,
  '@mediaMinMedium': {
    fs: 8,
  },
});

export default FilterChips;
