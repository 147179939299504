const ProductHexColors: { [key: string]: string } = {
  grey: '#5A5B67',
  black: '#0E0E10',
  red: '#C30300',
  silver: '#E1E1E3',
  ceramicwhite: '#F0F0F1',
  yellow: '#FFD80E',
  electricyellow: '#FFF500',
  lime: '#B1FC06',
  titan: '#787885',
  turquoise: '#3EAFCE',
  mint: '#91DBC6',
  olive: '#8B8361',
  bronze: '#CD7F32',
  violet: '#6E3446',
  lightblue: '#6E97AB',
  anthrazit: '#3A3A44',
  orange: '#FF6600',
  transparent: 'transparent',
  blue: '#1E355F',
  white: '#FFFFFF',
  gold: '#BBA14F',
  blackgold: '#0E0E10/#BBA14F',
  titanium: '#9696A0',
  blackorange: '#0E0E10/#FF6600',
  whiteorange: '#FFFFFF/#FF6600',
  electronicorange: '#FF9900',
  green: '#5E6452',
  beige: '#ADA39A',
  darkblue: '#101937',
  greymelange: '#675E61',
  pink: '#F854E9',
};

export default ProductHexColors;
