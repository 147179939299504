import { useMemo } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { timings } from 'Theme/Settings/animation';
import KexLink from '../../Kex/KexLink';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ColorModel from 'Models/Pages/ProductPage/ColorModel.interface';
import { isEmpty } from 'Shared/Common/Helpers';
import Price from 'Atoms/Price/Price';
import ColorOption from 'Atoms/ColorOption/ColorOption';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import ProductTag from 'Atoms/ProductTag/ProductTag';

type ProductCardType = {
  item: ProductCardModel;
  selectedModel?: string;
  inEditMode?: Boolean;
};

type ItemColorType = {
  colors: ColorModel[];
};

const ProductColors = ({ colors }: ItemColorType) => {
  return (
    <ColorFlexContainer>
      <ColorContainer>
        {colors.map((el, index) => (
          <ColorOption key={index} size="s" color={el.key} noMargin={true} />
        ))}
      </ColorContainer>
    </ColorFlexContainer>
  );
};

function MinimalProductCard({ item }: ProductCardType) {
  let {
    availableColors,
    code,
    image,
    secondaryImages,
    isUsed,
    mainCategory,
    name,
    nowPrice,
    wasPrice,
    secondaryDescription,
    url,
    promotion,
  } = item;

  const { productLabels } = useTranslationData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const { missingImage } = useAppSettingsData();
  const isMissingImage = isEmpty(image);
  const { used: usedLabel } = productLabels;

  const getPresetSuffix = () => {
    const usedOrNew = isUsed ? 'used' : 'new';
    const desktopOrMobile = isDesktop ? 'desktop' : 'mobile';
    return `?preset=product-card-${usedOrNew}-${desktopOrMobile}`;
  };

  const getPresetSuffixMemoized = useMemo(
    () => getPresetSuffix(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUsed, isDesktop]
  );

  return (
    <Card>
      <ProductTag text={promotion?.promotionName} />
      <ProductImageWrapper>
        {!isEmpty(secondaryImages) && (
          <SecondProductImage
            src={
              secondaryImages
                ? secondaryImages[0].src + getPresetSuffixMemoized
                : ''
            }
            alt={name}
            hasDarkBackgroundImage={!isUsed || isMissingImage}
          />
        )}
        <StyledKexLink href={url} key={code} underlined={false}>
          <ProductImage
            src={
              image
                ? image.src + getPresetSuffixMemoized
                : `${missingImage.src}${getPresetSuffixMemoized}`
            }
            alt={name}
            hasSecondImage={!isEmpty(secondaryImages)}
            hasDarkBackgroundImage={!isUsed || isMissingImage}
          />
        </StyledKexLink>

        {/* <StyledFavoriteButton>
          <FavoriteButton size="s" color="secondary" isActive={false} />
        </StyledFavoriteButton> */}
      </ProductImageWrapper>

      <StyledKexLink href={url} key={code} underlined={false}>
        {isDesktop && (
          <CategoryStyle>
            {isUsed && `${usedLabel.toUpperCase()}`}
            {isUsed && mainCategory && <Dot />}
            {mainCategory && `${mainCategory.toUpperCase()}`}
            {mainCategory && secondaryDescription && <Dot />}
            {secondaryDescription && `${secondaryDescription.toUpperCase()}`}
          </CategoryStyle>
        )}

        <ProductHeading>{name}</ProductHeading>

        <ProductBottom>
          {nowPrice.price !== 0 && (
            <ProductNowPrice>
              <Price
                friendlyWasPrice={wasPrice?.priceRoundedAsString}
                friendlyNowPrice={nowPrice?.priceRoundedAsString}
                wasPrice={wasPrice.price}
                nowPrice={nowPrice.price}
                size={isDesktop ? 'sm' : 'xs'}
              />
            </ProductNowPrice>
          )}
          {!isEmpty(availableColors) && (
            <ProductColors colors={availableColors} />
          )}
        </ProductBottom>
      </StyledKexLink>
    </Card>
  );
}

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const StyledKexLink = styled(KexLink, {
  display: 'block',
  mb: 0,
  fontSize: '20px',
  fontFamily: '$fontSecondary400',
  wordSpacing: 0,
  lineHeight: '$lh120',
  ls: '$ls0',
  gridColumn: '1',
  gridRow: '1',
  zIndex: '$ProductCardMain',
});

const Card = styled('div', {
  cursor: 'pointer',
  position: 'relative',
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'grid',
  gridTemplate: '1fr / 1fr',
});

const ASPECT_RATIO = '296 / 181';

const ProductImage = styled('img', {
  left: 0,
  transition: 'transform 0.2s ease-in-out',
  width: '100%',
  aspectRatio: ASPECT_RATIO,
  objectFit: 'cover',
  opacity: 1,
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.05)',
  },
  variants: {
    hasSecondImage: {
      true: {
        '&:hover': {
          transform: 'none',
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out',
        },
      },
    },
    hasDarkBackgroundImage: {
      true: {
        filter: '$colors$imageFilter',
      },
      false: {
        filter: 'none',
      },
    },
  },
});

const SecondProductImage = styled('img', {
  left: 0,
  width: '100%',
  aspectRatio: ASPECT_RATIO,
  objectFit: 'cover',
  gridColumn: '1',
  gridRow: '1',
  zIndex: '$ProductCardSecondary',
  variants: {
    hasDarkBackgroundImage: {
      true: {
        filter: '$colors$imageFilter',
      },
      false: {
        filter: 'none',
      },
    },
  },
});

const CategoryStyle = styled('div', {
  display: 'flex',
  mt: 4,
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$ls1',
  color: '$secondary2',
  fs: 6,
});

const ProductHeading = styled('h3', {
  fontFamily: '$fontSecondary400',
  fs: 7,
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0px',
  height: '38px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  mt: 2,
  mb: 4,
  textDecoration: 'underline solid transparent',
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  textUnderlineOffset: '2px',
  transitionTimingFunction: 'ease-in-out',
  '@mediaMinLarge': {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    height: '48.7px',
  },
  variants: {
    underlined: {
      true: {
        textDecoration: 'underline solid',
      },
    },
  },
});

const ProductBottom = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const ColorFlexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginLeft: 'auto',
});

const ColorContainer = styled('div', {
  mt: 4,
  mb: 2,
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '4px',
});

const ProductNowPrice = styled('div', {
  ...fontStyleCss,
  mt: 4,
  mb: 2,
  lineHeight: '$lh133',
  fs: 6,
  '@mediaMinLarge': {
    fs: 8,
    fontWeight: 400,
    lineHeight: '24px',
  },
});

const Dot = styled('div', {
  h: 1,
  w: 1,
  borderRadius: '50%',
  backgroundColor: '#eee',
  alignSelf: 'center',
  justifySelf: 'center',
  position: 'relative',
  mx: 2,
});

export default MinimalProductCard;
