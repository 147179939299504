import SearchTranslations from 'Models/Translations/SearchTranslations.interface';

export const mapSearchTranslations = (
  translations: SearchTranslations,
  value: string,
  isUnit: boolean = false
) => {
  if (!translations || !value) return '';
  return translations[
    ('facet' +
      value.replace('$', '').replace('.', '') +
      (isUnit ? 'Unit' : '')) as keyof SearchTranslations
  ];
};
