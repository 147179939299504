import { styled } from 'stitches.config';
import { timings } from 'Theme/Settings/animation';
import { CSS } from '@stitches/react';

type PropType = {
  isChecked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  text?: string;
  value?: string | number;
  css?: CSS;
  useAbsolute?: true;
  radioBackgroundColor?: 'primary6' | 'primary3';
};

function RadioButton({
  isChecked,
  onChange,
  value,
  text,
  css,
  useAbsolute,
  radioBackgroundColor = 'primary6',
}: PropType) {
  return (
    <RadioButtonWrapper css={css}>
      <Label>
        <Input
          type="radio"
          id={value?.toString()}
          value={value}
          checked={isChecked}
          onChange={onChange}
          tabIndex={-1}
        />
        <Radio
          isChecked={isChecked}
          radioBackgroundColor={radioBackgroundColor}
        />
        {text && (
          <RadioButtonText useAbsolute={useAbsolute}>{text}</RadioButtonText>
        )}
      </Label>
    </RadioButtonWrapper>
  );
}

export default RadioButton;

const RadioButtonWrapper = styled('div', {});

const Input = styled('input', {
  opacity: 0,
  wh: 0,
});

const Label = styled('label', {
  display: 'flex',
  cursor: 'pointer',
  color: '$radioTextColor',
  alignItems: 'center',
  fs: 6,
});

const RadioButtonText = styled('span', {
  position: 'relative',
  ml: 2.5,
  variants: {
    useAbsolute: {
      true: {
        position: 'absolute',
        ml: 6,
      },
    },
  },
});

const Radio = styled('div', {
  cursor: 'pointer',

  tblr: 0,
  backgroundColor: '$radioIconColor',
  wh: 4,
  br: 34,
  position: 'relative',
  '&:before': {
    position: 'absolute',
    content: '""',
    wh: 3,
    l: 0.5,
    b: 0.5,
    backgroundColor: '$radioBackground',
    br: '50%',
    transition: `background-color ${timings.threeTenths} ease-in-out`,
  },

  variants: {
    isChecked: {
      true: {
        '&:before': {
          backgroundColor: '$radioIconColor',
          bw: 0.5,
          borderColor: '$radioBackground',
          wh: 3,
          l: 0.5,
          b: 0.5,
          br: '50%',
        },
      },
    },
    radioBackgroundColor: {
      primary6: {
        isChecked: {
          false: {
            '&:before': {
              backgroundColor: '$radioBackground',
            },
          },
        },
      },
      primary3: {
        isChecked: {
          false: {
            '&:before': {
              backgroundColor: '$primary3',
            },
          },
        },
      },
    },
  },
});
