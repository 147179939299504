import Button from 'Atoms/Buttons/Button';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import KexFacet from 'Models/Search/KexFacet.interface';
import Modal from 'Organisms/Modal/Modal';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import FilterItem from './FilterItem';
import { useFilterData } from './FilterProvider';

type PropTypes = {
  showModal: boolean;
  toggle: () => void;
  facets: KexFacet[];
  query: string;
  productHits: number;
};
function FilterModal({
  showModal,
  toggle,
  facets,
  query,
  productHits,
}: PropTypes) {
  const {
    searchLabels: { show, clearFilters, filters },
  } = useTranslationData();

  const [, dispatch] = useFilterData();

  return (
    <Modal
      showModal={showModal}
      toggle={toggle}
      overlay={true}
      noPadding
      showScrollbar={false}
    >
      <ModalContent>
        <div>
          <HeaderContainer>
            <H2 css={{ mt: 0 }}>{filters}</H2>
          </HeaderContainer>
          <ScrollWrapper>
            {facets &&
              facets.map((item: KexFacet, index: number) => (
                <FilterItem key={item.name} item={item} query={query} isModal />
              ))}
          </ScrollWrapper>
        </div>

        <ButtonContainer>
          <Button
            css={{ mr: 6 }}
            fullWidth
            type="tertiary"
            onClick={() => dispatch({ type: 'onClear' })}
          >
            {clearFilters}
          </Button>
          <Button fullWidth type="secondary" onClick={toggle}>
            {show} ({productHits})
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled('div', {
  color: 'textPrimary',
  pt: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  backgroundColor: '$modalContentBackground',
  '@mediaMinLarge': {
    pt: 16,
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const ScrollWrapper = styled('div', {
  pb: 4,
});

const ButtonContainer = styled('div', {
  position: 'sticky',
  w: '100%',
  b: 0,
  py: 8,
  px: 6,
  display: 'flex',
  alignSelf: 'end',
  zIndex: '$Modal',
  justifySelf: 'end',
  backgroundColor: '$filterModalButtonBackground',
});

const HeaderContainer = styled('div', {
  ml: 6,
});

export default FilterModal;
