import { styled } from 'stitches.config';
import { DropDownItem } from 'Molecules/DropDowns/BaseDropDownStyle';
import Checkbox from 'Atoms/Checkbox/Checkbox';

type Values = {
  value: string;
  key: string;
  count: number;
};

type PropTypes = {
  name: string;
  values: Array<Values>;
  placeholderLabel: string;
  handleChange: (
    e: React.MouseEvent<HTMLLIElement>,
    key: string,
    name: string,
    isChecked: boolean
  ) => void;
  selectedValues: string[];
};

export function MultiSelectCollapsableContent({
  name,
  values,
  handleChange,
  selectedValues = [],
}: PropTypes) {
  const anyChecked = !!selectedValues.length;

  return (
    <>
      {values &&
        values.length > 0 &&
        values.map((item) => {
          const isChecked = selectedValues.some(
            (selected) => selected === item.key
          );

          return (
            <DropDownItem
              key={item.key}
              onClick={(e) => handleChange(e, item.key, name, isChecked)}
            >
              <DropDownItemLabel>
                <Checkbox
                  id={item.key}
                  text={item.value}
                  isChecked={isChecked}
                  onCheck={() => {}}
                />
              </DropDownItemLabel>
              {(!anyChecked || isChecked) && (
                <CounterStyle>{item.count}</CounterStyle>
              )}
            </DropDownItem>
          );
        })}
    </>
  );
}

export default MultiSelectCollapsableContent;

const DropDownItemLabel = styled('div', {
  display: 'flex',
});

const CounterStyle = styled('span', {
  color: '$dropdownCounter',
});
