import Image from 'Atoms/Image/Image';
import KexLink from 'Kex/KexLink';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PropTypes = {
  text: string;
  imageHref: string;
  href: string;
  large?: boolean;
  onClick?: () => void;
};

function CategorySubLinksCard({
  text = '',
  imageHref = '',
  href = '/',
  onClick,
}: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  return (
    <KexLink
      href={!onClick ? href : ''}
      css={KexLinkStyle}
      fontFamily={isMobile ? 'secondary400' : 'primary400'}
      underlined={false}
    >
      <Wrapper>
        <ImageWrapper
          css={!imageHref ? (isMobile ? { display: 'none' } : {}) : {}}
        >
          <Image
            src={
              isMobile
                ? `${imageHref}?preset=mobile-category-image`
                : `${imageHref}?preset=desktop-category-image`
            }
            alt={'image'}
          />
        </ImageWrapper>
        <StyledLinkWrapper onClick={onClick && onClick}>
          {isMobile ? text.toLowerCase() : text}
        </StyledLinkWrapper>
      </Wrapper>
    </KexLink>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'start',
  backgroundColor: '$primary4',
  py: 2,
  px: 4,
  mb: 4,
  h: 16,
  '@mediaMinLarge': {
    justifyContent: 'center',
    flexDirection: 'column',
    h: 36,
    w: 48,
    mb: 0,
  },
});

const KexLinkStyle = {
  w: '100%',
  m: 0,
  '@mediaMinLarge': {
    w: 'auto',
    mr: 8,
    textTransform: 'uppercase',
  },
};

const StyledLinkWrapper = styled('div', {
  width: 'fit-content',
  display: 'flex',
  textTransform: 'capitalize',
  ml: 4,
  '@mediaMinLarge': {
    ml: 0,
    textTransform: 'uppercase',
  },
});

const ImageWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  '@mediaMinLarge': {
    mb: 2,
    justifyContent: 'center',
  },
});

export default CategorySubLinksCard;
