import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';

const DropDownContainer = styled('div', {
  w: 'fit-content',
  position: 'relative',
  h: 'auto',
  display: 'block',
  textAlign: 'left',
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

const DropDownHeader = styled('button', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  py: 2,
  px: 4,
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$dropdownHeaderBackground',
  transition: `background-color ${timings.threeTenths} ${animation.timingFn}`,
  width: 'inherit',
  variants: {
    isOpen: {
      true: {
        backgroundColor: '$primary2',
      },
    },
  },
});

const DropDownChildren = styled('ul', {
  lineHeight: '$lh133',
  p: 4,
  h: 'auto',
  maxHeight: 500,
  width: 'inherit',
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid $dropdownBorder',
  backgroundColor: '$dropdownChildrenBackground',
  opacity: 0,
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  overflow: 'auto',
  position: 'absolute',
  top: 40,
  left: 0,
  zIndex: '$DropDown',
  visibility: 'hidden',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
});

const DropDownItem = styled('li', {
  cursor: 'pointer',
  w: '-webkit-fill-available',
  h: '100%',
  textAlign: 'left',
  color: '$dropdownText',
  fs: 6,
  '&:not(:last-child)': {
    pb: 4,
  },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover label': {
    textDecoration: 'underline',
  },
});

const CurrentValue = styled('span', {
  color: '$dropdownText',
  fontFamily: '$fontPrimary400',
  wordSpacings: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$lhn1',
  fs: 9,
  '@mediaMinMedium': {
    fs: 8,
  },
  mr: 3,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export {
  DropDownContainer,
  DropDownHeader,
  DropDownChildren,
  CurrentValue,
  DropDownItem,
};
