import { ChevronIcon } from 'Atoms/Icons';
import { useRef, useState } from 'react';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';

type PropTypes = {
  label: string;
  children: React.ReactNode;
  inRow?: boolean;
  isRange?: boolean;
};

export function MultiSelectCollapsable({ label, children, inRow }: PropTypes) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [innerHeight, setInnerHeight] = useState<boolean>(false);

  const onToggle = () => {
    if (innerHeight) {
      setTimeout(() => setInnerHeight(!innerHeight), 300);
    } else {
      setInnerHeight(!innerHeight);
    }
    setIsOpen((s) => !s);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef, () => {
    if (inRow) {
      setIsOpen(false);
      if (innerHeight) {
        setTimeout(() => setInnerHeight(!innerHeight), 300);
      }
    }
  });

  return (
    <Wrapper
      id={inRow ? 'rowwy' : undefined}
      className="yello"
      inRow={inRow}
      ref={wrapperRef}
    >
      <TitleWrapper onClick={onToggle} isOpen={isOpen}>
        <CurrentValue css={{ fs: inRow ? 8 : 9, pr: inRow ? 3 : 0 }}>
          {label}{' '}
        </CurrentValue>
        <ChevronIcon
          size="m"
          css={{
            m: 0,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
          }}
          color="secondary"
        />
      </TitleWrapper>
      <InnerWrapper inRow={inRow} isOpen={isOpen} innerHeight={innerHeight}>
        {children}
      </InnerWrapper>
    </Wrapper>
  );
}

export default MultiSelectCollapsable;

const CurrentValue = styled('span', {
  color: '$dropdownText',
  fontFamily: '$fontPrimary400',
  wordSpacings: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$lhn1',
  fs: 8,
  wordSpacing: '$wordSpacings$fontPrimary',
});

const Wrapper = styled('div', {
  position: 'relative',
  textAlign: 'left',
  borderColor: '$collapsableBorderColor',
  borderStyle: 'solid',
  w: '100%',
  mb: 1,
  variants: {
    fullWidth: {
      true: {
        maxWidth: 'none',
      },
    },
    inRow: {
      true: {
        mb: 0,
        mr: 4,
      },
    },
  },
});

const TitleWrapper = styled('div', {
  color: '$collapsableText',
  display: 'flex',
  py: 5,
  px: 4,
  justifyContent: 'space-between',
  fontFamily: '$fontPrimary400',
  backgroundColor: '$collapsableHeaderBackground',
  alignItems: 'center',
  cursor: 'pointer',
  fs: 10,
  lh: '$lh133',
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  variants: {
    isOpen: {
      false: {
        '@mediaMinLarge': {
          '&:hover': {
            backgroundColor: '$collapsableHoverBackground',
          },
        },
      },
    },
  },
  ls: '$lsn1',
  '@mediaMinLarge': {
    px: 4,
    py: 2,
  },
});

const InnerWrapper = styled('div', {
  opacity: 0,
  maxHeight: 0,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  color: '$collapsableText',
  backgroundColor: '$collapsableContentBackground',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'opacity',

  variants: {
    inRow: {
      true: {
        position: 'absolute',
        top: 40,
        left: 0,
        zIndex: 9,
      },
    },
    isOpen: {
      true: {
        opacity: 1,
      },
    },
    innerHeight: {
      true: {
        maxHeight: 'unset',
        p: 4,
        '@mediaMinLarge': {
          p: 6,
        },
      },
    },
  },
});
