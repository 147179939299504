import KexFacet from 'Models/Search/KexFacet.interface';
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from 'stitches.config';
import { useFilterData } from 'Organisms/FilterComponent/FilterProvider';
import KexTerm from 'Models/Search/KexTerm.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import FilterChips from 'Atoms/FilterChips/FilterChips';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import SearchResultProductModel from 'Models/Search/SearchResultProductModel.interface';

const CATEGORY = 'GetParentCategory.DisplayName';

type PropTypes = {
  facets: KexFacet[];
  productResults?: SearchResultProductModel;
  pageTitle?: string;
};

function CategoryListContainer({
  facets,
  productResults,
  pageTitle,
}: PropTypes) {
  const [state, dispatch] = useFilterData();
  const [categories, setCategories] = useState<KexTerm[]>([]);
  const [resultCategories, setResultCategories] = useState<KexTerm[]>([]);
  const [currentPageId, setCurrentPageId] = useState<number>(0);
  const { pageId } = useCurrentPage<PageModelBase>();

  useEffect(() => {
    if (currentPageId !== pageId) {
      setCategories([]);
      setCurrentPageId(pageId);
    }

    const item = facets?.find((element) => element.name === CATEGORY);
    if (!AreTermsEqual(item?.terms, categories) || categories.length === 0) {
      setCategories(item?.terms || []);
    }
  }, [facets, pageId, categories, currentPageId]);

  useEffect(() => {
    const item = productResults?.facets?.find(
      (element) => element.name === CATEGORY
    );
    setResultCategories(item?.terms || []);
  }, [productResults?.facets, categories]);

  const isChecked = (termName: string) => {
    return !!state.multiSelectFilters.get(CATEGORY)?.has(termName);
  };

  const showCategoryList = useMemo(
    () => categories[0] && categories[0]?.term === pageTitle,
    [categories, pageTitle]
  );

  const AreTermsEqual = (obj1: KexTerm[] | undefined, obj2: KexTerm[]) => {
    const a = JSON.stringify(obj1?.map((x) => x.term));
    const b = JSON.stringify(obj2?.map((x) => x.term));
    const result = a === b;

    return result;
  };

  const changeContentType = (termName: string) =>
    dispatch({
      type: 'setMultiSelectFilter',
      key: CATEGORY,
      value: termName,
      checked: !isChecked(termName),
    });

  if (showCategoryList) return <React.Fragment />;
  return (
    <ContentContainer noPadding>
      <Wrapper>
        {Array.from(categories).map((item) => (
          <div key={item.term}>
            {item.term !== pageTitle && (
              <FilterChips
                isChecked={isChecked(item.term)}
                amount={
                  resultCategories.find((obj) => obj.term === item.term)
                    ?.count || 0
                }
                onCheck={() => {
                  changeContentType(item.term);
                }}
                text={item.term}
              />
            )}
          </div>
        ))}
      </Wrapper>
    </ContentContainer>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  mx: 'auto',
  pt: 4,
  pb: 3,
  g: 4,
  '@mediaMaxLarge': {
    overflowX: 'scroll',
    w: '100vw',
    px: 4,
    left: '50%',
    right: '50%',
    maxWidth: '100vw',
    position: 'relative',
    marginLeft: `-50vw`,
    marginRight: '-50vw',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      display: 'none',
    },
    '&::scrollbar': {
      display: 'none',
    },
  },
  '@mediaMinLarge': {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    pt: 8,
  },
});

export default CategoryListContainer;
