import { ChevronIcon } from 'Atoms/Icons';
import { useRef, useState } from 'react';
import { styled } from 'stitches.config';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownChildren,
  DropDownItem,
  CurrentValue,
} from './BaseDropDownStyle';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import Checkbox from '../../Atoms/Checkbox/Checkbox';

type Values = {
  value: string;
  key: string;
  count: number;
};

type PropTypes = {
  name: string;
  values: Array<Values>;
  placeholderLabel: string;
  handleChange: (
    e: React.MouseEvent<HTMLLIElement>,
    key: string,
    name: string,
    value: string,
    isChecked: boolean
  ) => void;
  selectedValues: string[];
};

function padName(name: string, desiredLength: number) {
  const l = name.length;

  if (l >= desiredLength) {
    return name;
  }

  let padding = '';

  for (let i = 0; i < desiredLength - l; i++) {
    padding += ' ';
  }

  return name + padding;
}

export function MultiSelectDropDown({
  name,
  values,
  placeholderLabel,
  handleChange,
  selectedValues = [],
}: PropTypes) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const header = padName(
    placeholderLabel,
    Math.max(...values.map((m) => m.value.length))
  );

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  function onSetIsOpen() {
    setIsOpen(!isOpen);
  }

  const anyChecked = !!selectedValues.length;

  return (
    <DropDownContainer ref={ref}>
      <DropDownHeader onClick={onSetIsOpen} isOpen={isOpen}>
        <CurrentValue>{header} </CurrentValue>
        <ChevronIcon
          size="m"
          css={{
            m: 0,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
          }}
          color="secondary"
        />
      </DropDownHeader>

      <DropDownChildren
        isOpen={isOpen}
        role="listbox"
        tabIndex={-1}
        aria-labelledby="ss_imp_l"
      >
        {values &&
          values.length > 0 &&
          values.map((item) => {
            const isChecked = selectedValues.some(
              (selected) => selected === item.key
            );

            return (
              <DropDownItem
                key={item.value}
                onClick={(e) =>
                  handleChange(e, item.key, name, item.value, isChecked)
                }
                role="option"
                tabIndex={0}
              >
                <DropDownItemLabel>
                  <Checkbox
                    id={item.key}
                    text={item.value}
                    isChecked={isChecked}
                    onCheck={() => {}}
                  />
                </DropDownItemLabel>
                {(!anyChecked || isChecked) && (
                  <CounterStyle>{item.count}</CounterStyle>
                )}
              </DropDownItem>
            );
          })}
      </DropDownChildren>
    </DropDownContainer>
  );
}

export default MultiSelectDropDown;

const DropDownItemLabel = styled('div', {
  display: 'flex',
  whiteSpace: 'nowrap',
});

const CounterStyle = styled('span', {
  color: '$dropdownCounter',
  ml: 4,
});
