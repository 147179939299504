import React, { useRef } from 'react';
import useOnScreen from 'Shared/Hooks/useOnScreen';

type PropType = {
  src: string;
  alt: string;
  imgFit?: boolean;
  onLoad?: () => void;
  usePlaceHolder?: boolean;
  title?: string;
  fixedWidth?: number;
  fixedHeight?: number;
  externalRef?: React.RefObject<HTMLDivElement>;
};

const Image = ({
  src,
  alt = '',
  onLoad,
  usePlaceHolder,
  title = '',
  fixedWidth,
  fixedHeight,
  externalRef,
}: PropType) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen({ ref: externalRef ? externalRef : ref });

  return onScreen ? (
    <img
      src={onScreen ? src : ''}
      alt={alt || ''}
      title={title}
      aria-label={alt}
      onLoad={onLoad}
      width={fixedWidth}
      height={fixedHeight}
    />
  ) : usePlaceHolder ? (
    <div ref={ref} />
  ) : (
    <span ref={ref} />
  );
};

export default Image;
