import SearchResultProductModel from 'Models/Search/SearchResultProductModel.interface';
import SingleSelectDropDown from 'Molecules/DropDowns/SingleSelectDropDown';
import { useCallback, useMemo } from 'react';
import { styled } from 'stitches.config';
import { useFilterData } from './FilterProvider';

type PropTypes = {
  productResult: SearchResultProductModel;
};

function SorterContainer({ productResult }: PropTypes) {
  const [{ sorterFilter }, dispatch] = useFilterData();

  const sortList = useMemo(() => {
    return productResult?.sorters.map((item) => {
      return {
        key: item.value,
        value: item.text,
      };
    });
  }, [productResult?.sorters]);

  const handleSelect = useCallback(
    (_key: number, value: string) => {
      dispatch({
        type: 'setSorterFilterByText',
        value,
      });
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <SingleSelectDropDown
          headerCSS={customHeaderCSS}
          childrenCSS={customChildrenCSS}
          handleSelect={handleSelect}
          values={sortList}
          currentValue={sorterFilter.text}
        />
      </Container>
    </>
  );
}

export default SorterContainer;

const customHeaderCSS = {
  width: 150,
  '@mediaMinLarge': {
    minWidth: 150,
    width: 'auto',
  },
};

const customChildrenCSS = {
  r: 0,
  left: 'unset',
  width: 150,
  '@mediaMinLarge': { minWidth: 150, width: 'auto', left: 0 },
};

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  flexDirection: 'row',
  color: '$textPrimary',
  backgroundColor: '$contentResultBackgroundPrimary',
});
