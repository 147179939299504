import { CSS } from '@stitches/react';
import { styled } from 'stitches.config';

type PropType = {
  text: string;
  css?: CSS;
};

function ProductTag({ text, css = { top: 8, left: -8 } }: PropType) {
  if (!text) return <></>;

  return <ProductTagContainer css={css}>{text}</ProductTagContainer>;
}

export default ProductTag;

const ProductTagContainer = styled('span', {
  backgroundColor: '$campaignLabelBackground',
  color: '$campaignLabelText',
  fontFamily: '$fontSecondary500',
  fs: 6,
  lineHeight: '$lh133',
  textAlign: 'center',
  px: 2,
  py: 0.5,
  position: 'absolute',
  top: 8,
  left: -8,
  zIndex: '$ProductCardTag',
});
