import { ChevronIcon } from 'Atoms/Icons';
import { useRef, useState } from 'react';
import { styled } from 'stitches.config';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownChildren,
  DropDownItem,
  CurrentValue,
} from './BaseDropDownStyle';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import RadioButton from '../../Atoms/RadioButton/RadioButton';
import { CSS } from '@stitches/react';
import { isEmpty } from 'Shared/Common/Helpers';

type Value = {
  key: number;
  value: string;
};

type PropTypes = {
  values: Value[] | undefined;
  currentValue: string;
  placeholderLabel?: string;
  handleSelect: (key: number, value: string) => void;
  headerCSS?: CSS;
  childrenCSS?: CSS;
  fullWidth?: boolean;
};

export function SingleSelectDropDown({
  values,
  currentValue,
  handleSelect,
  headerCSS,
  childrenCSS,
  fullWidth,
}: PropTypes) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsOpen(false));

  function onSetIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <DropDownContainer ref={ref} fullWidth={fullWidth}>
      <DropDownHeader onClick={onSetIsOpen} isOpen={isOpen} css={headerCSS}>
        <CurrentValue>{currentValue}</CurrentValue>
        <ChevronIcon
          size="m"
          css={{
            m: 0,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
          }}
          color="secondary"
        />
      </DropDownHeader>

      <DropDownChildren
        isOpen={isOpen}
        css={{ ...childrenCSS, top: ref.current?.offsetHeight }}
        tabIndex={-1}
      >
        {!isEmpty(values) &&
          values?.map((item: Value, index: number) => (
            <DropDownItem
              key={`${item.key}${index}`}
              onClick={(e) => {
                e.preventDefault();

                handleSelect(item.key, item.value);
                setIsOpen(false);
              }}
              tabIndex={0}
            >
              <DropDownItemLabel>
                <RadioButton
                  isChecked={item.value === currentValue}
                  text={item.value}
                  value={item.key}
                  onChange={() => {}}
                />
              </DropDownItemLabel>
            </DropDownItem>
          ))}
      </DropDownChildren>
    </DropDownContainer>
  );
}

export default SingleSelectDropDown;

const DropDownItemLabel = styled('div', {
  display: 'flex',
});
