import { styled } from 'stitches.config';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  outerFilter: { min: number; max: number };
  onChange: (min: number, max: number) => void;
  unit: string;
}
const MultiRangeSlider = ({
  min,
  max,
  onChange,
  outerFilter,
  unit,
}: MultiRangeSliderProps) => {
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  const [currentMin, setCurrentMin] = useState(outerFilter.min);
  const [currentMax, setCurrentMax] = useState(outerFilter.max);
  const [currentMinText, setCurrentMinText] = useState(`${outerFilter.min}`);
  const [currentMaxText, setCurrentMaxText] = useState(`${outerFilter.max}`);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    setCurrentMax(outerFilter.max);
  }, [outerFilter.max]);
  useEffect(() => {
    setCurrentMin(outerFilter.min);
  }, [outerFilter.min]);

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(currentMin);
      const maxPercent = getPercent(+maxValRef.current.value);
      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [currentMin, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(currentMax);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [currentMax, getPercent]);

  const setMin = (value: string) => {
    setCurrentMinText(value);
    const asNumber = Number.parseInt(value);

    if (Number.isNaN(asNumber)) {
      return;
    }

    const clampedValue = Math.max(min, Math.min(asNumber, currentMax));

    if (asNumber > max) {
      setCurrentMinText(clampedValue.toString());
    }

    setCurrentMin(clampedValue);
    onChange(clampedValue, currentMax);
  };

  const setMax = (value: string) => {
    setCurrentMaxText(value);
    const asNumber = Number.parseInt(value);

    if (Number.isNaN(asNumber)) {
      return;
    }

    const clampedValue = Math.max(currentMin, Math.min(asNumber, max));

    if (clampedValue === asNumber) {
      setCurrentMax(clampedValue);
    }
    onChange(clampedValue, currentMax);
  };

  const onBlurInput = () => {
    let minNum = Number.parseInt(currentMinText);
    let maxNum = Number.parseInt(currentMaxText);

    if (!Number.isInteger(minNum)) {
      minNum = currentMin;
    }

    if (!Number.isInteger(maxNum)) {
      maxNum = currentMax;
    }

    if (minNum > maxNum) {
      const temp = minNum;
      minNum = maxNum;
      maxNum = temp;
    }

    minNum = Math.max(min, Math.min(minNum, max));
    maxNum = Math.max(min, Math.min(maxNum, max));

    setCurrentMin(minNum);
    setCurrentMax(maxNum);
    setCurrentMinText(minNum.toString());
    setCurrentMaxText(maxNum.toString());
    onChange(minNum, maxNum);
  };

  const onlyNumbers = (code: string, key: string) => {
    if (
      ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Space'].includes(code)
    ) {
      return true;
    }

    return !isNaN(Number(key));
  };

  return (
    <Container>
      <StyledRange
        css={{ zIndex: 2 }}
        type="range"
        min={min}
        max={max}
        value={currentMin}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, currentMax);
          setCurrentMin(value);
          setCurrentMinText(value.toString());
          event.target.value = value.toString();
        }}
        onMouseUp={() => onChange(currentMin, currentMax)}
        onTouchEnd={() => onChange(currentMin, currentMax)}
      />
      <StyledRange
        type="range"
        css={{ zIndex: 1 }}
        min={min}
        max={max}
        value={currentMax}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, currentMin);
          setCurrentMax(value);
          setCurrentMaxText(value.toString());
          event.target.value = value.toString();
        }}
        onMouseUp={() => onChange(currentMin, currentMax)}
        onTouchEnd={() => onChange(currentMin, currentMax)}
      />

      <Slider>
        <SliderTrack />
        <SliderRange ref={range} />
      </Slider>
      <ValueContainer>
        <ValueInputWrapper>
          <ValueAsInput
            value={currentMinText}
            type="number"
            onBlur={onBlurInput}
            onKeyDown={(e) => onlyNumbers(e.code, e.key)}
            onChange={(e) => setMin(e.target.value)}
          />
          <div>{unit}</div>
        </ValueInputWrapper>

        <ValueInputWrapper>
          <ValueAsInput
            value={currentMaxText}
            type="number"
            onBlur={onBlurInput}
            onKeyDown={(e) => onlyNumbers(e.code, e.key)}
            onChange={(e) => setMax(e.target.value)}
          />
          <div>{unit}</div>
        </ValueInputWrapper>
      </ValueContainer>
    </Container>
  );
};

const StyledRange = styled('input', {
  pointerEvents: 'none',
  position: 'absolute',
  height: '0',
  width: '100%',
  outline: 'none',
  right: 0,
  WebkitAppearance: 'none',
  '&::-webkit-slider-thumb': {
    WebkitAppearance: 'none',
    backgroundColor: '$secondary2',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    height: '18px',
    width: '18px',
    pointerEvents: 'all',
    position: 'relative',
    marginTop: '2px',
  },
  '&::-moz-range-thumb': {
    backgroundColor: '$secondary2',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    height: '18px',
    width: '18px',
    pointerEvents: 'all',
    position: 'relative',
    marginTop: '2px',
  },
});

const ValueContainer = styled('div', {
  flexDirection: 'row',
  display: 'flex',
  color: '$textPrimary',
  mt: 10,
  justifyContent: 'space-between',
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh133',
  fs: 6,
});

const SliderTrack = styled('div', {
  position: 'absolute',
});

const ValueInputWrapper = styled('div', {
  outline: '1px solid $primaryLight4',
  p: '12px 16px',
  h: 12,
  fs: 8,
  display: 'grid',
  gridTemplateColumns: '96px auto',
  alignItems: 'center',
});

const ValueAsInput = styled('input', {
  appearance: 'none',
  WebkitAppearance: 'none',
  outline: 'none',
  background: 'transparent',
  color: '$secondary2',
  '&:focus': {
    outline: 'none',
  },
  '-moz-appearance': 'textfield',
  '&::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const Slider = styled('div', {
  position: 'relative',
  width: '100%',
  height: '2px',
  backgroundColor: '$JE68GreenBackground',
});

const SliderRange = styled('div', {
  position: 'absolute',
  height: '2px',
  backgroundColor: '$JE68GreenPrimary',
});

const Container = styled('div', {
  width: '100%',
  mt: 4,
  position: 'relative',
});

export default MultiRangeSlider;
