import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import { styled } from 'stitches.config';
import { timings } from 'Theme/Settings/animation';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import KexLink from '../../Kex/KexLink';
import { useMemo } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { FILTER_URL_CONSTANTS, isEmpty } from 'Shared/Common/Helpers';
import ColorModel from 'Models/Pages/ProductPage/ColorModel.interface';
import { ProductType } from 'Enums/ProductType.enum';
import MinimalProductCard from './MinimalProductCard';
import Price from 'Atoms/Price/Price';
import ColorOption from 'Atoms/ColorOption/ColorOption';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import ProductTag from 'Atoms/ProductTag/ProductTag';

type ProductCardType = {
  item: ProductCardModel;
  selectedModel?: string;
  inEditMode?: Boolean;
};

type ItemType = {
  name: string;
  value: React.ReactNode;
};

type ItemColorType = {
  name: string;
  colors: ColorModel[];
};

const InfoItem = ({ name, value }: ItemType) => {
  return (
    <FlexContainer>
      <InfoText>{name}</InfoText>
      {Array.isArray(value) ? (
        <InfoText>{value.map((el) => el)}</InfoText>
      ) : (
        <InfoText>{value}</InfoText>
      )}
    </FlexContainer>
  );
};

const InfoItemColor = ({ name, colors }: ItemColorType) => {
  const MAX_COLORS_LIMIT = 3;
  return (
    <FlexContainer>
      <InfoText>
        {name}{' '}
        {colors.length > MAX_COLORS_LIMIT && (
          <span style={{ fontSize: 12, verticalAlign: 'middle' }}>
            +{colors.length - MAX_COLORS_LIMIT}
          </span>
        )}
      </InfoText>
      <ColorContainer>
        {colors.slice(0, MAX_COLORS_LIMIT).map((el, index) => (
          <ColorOption key={index} size="s" color={el.key} />
        ))}
      </ColorContainer>
    </FlexContainer>
  );
};

function formatUrl(url: string, selectedModel?: string) {
  if (!selectedModel) {
    return url;
  }

  const separator = url.includes('?') ? '&' : '?';

  const fUrl = `${url}${separator}${FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER}=${selectedModel}`;
  return fUrl;
}

function ProductCard({ item, selectedModel }: ProductCardType) {
  const {
    availableColors,
    code,
    driveLine,
    driversLicenseClass,
    image,
    secondaryImages,
    isUsed,
    mainCategory,
    odometer,
    odometerUnit,
    name,
    numberOfCylinders,
    nowPrice,
    wasPrice,
    productType,
    secondaryDescription,
    url,
    promotion,
  } = item;

  const odometerFormatted = `${odometer} ${odometerUnit ? odometerUnit : ''}`;
  const { missingImage } = useAppSettingsData();

  const { productLabels } = useTranslationData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const isMissingImage = isEmpty(image);
  const {
    driversLicense: driversLicenseLabel,
    driveLine: driveLineLabel,
    cylinders: cylindersLabel,
    odometer: odometerLabel,
    color: colorLabel,
    used: usedLabel,
  } = productLabels;

  const getPresetSuffixMemoized = useMemo(() => {
    const usedOrNew = isUsed ? 'used' : 'new';
    const desktopOrMobile = isDesktop ? 'desktop' : 'mobile';

    return `?preset=product-card-${usedOrNew}-${desktopOrMobile}`;
  }, [isUsed, isDesktop]);

  if (productType !== ProductType.MOTORCYCLES) {
    return (
      <MinimalProductCard
        item={{ ...item, url: formatUrl(url, selectedModel) }}
      />
    );
  }

  return (
    <Card href={url} underlined={false}>
      <ProductTag text={promotion?.promotionName} />
      <ProductImageWrapper>
        {!isEmpty(secondaryImages) && (
          <SecondProductImage
            src={
              secondaryImages
                ? secondaryImages[0].src + getPresetSuffixMemoized
                : ''
            }
            alt={name}
            hasDarkBackgroundImage={!isUsed || isMissingImage}
          />
        )}
        <Wrapper key={code}>
          <ProductImage
            src={
              image
                ? image.src + getPresetSuffixMemoized
                : `${missingImage.src}${getPresetSuffixMemoized}`
            }
            alt={name}
            hasSecondImage={!isEmpty(secondaryImages)}
            hasDarkBackgroundImage={!isUsed || isMissingImage}
          />
        </Wrapper>

        {/* <StyledFavoriteButton>
          <FavoriteButton size="s" color="secondary" isActive={false} />
        </StyledFavoriteButton> */}
      </ProductImageWrapper>

      <Wrapper key={code}>
        <CategoryStyle>
          {isUsed && `${usedLabel.toUpperCase()}`}
          {isUsed && mainCategory && <Dot />}
          {mainCategory && `${mainCategory.toUpperCase()}`}
          {mainCategory && secondaryDescription && <Dot />}
          {secondaryDescription && `${secondaryDescription.toUpperCase()}`}
        </CategoryStyle>
        <ProductHeading>{name}</ProductHeading>
        <InfoContainer>
          {driversLicenseClass && driversLicenseClass.length > 0 && (
            <InfoItem
              name={driversLicenseLabel}
              value={driversLicenseClass.join(', ')}
            />
          )}
          {driveLine && <InfoItem name={driveLineLabel} value={driveLine} />}
          {numberOfCylinders && (
            <InfoItem name={cylindersLabel} value={numberOfCylinders} />
          )}
          {isUsed && parseInt(odometer) > 0 && (
            <InfoItem name={odometerLabel} value={odometerFormatted} />
          )}
          {!isEmpty(availableColors) && (
            <InfoItemColor name={colorLabel} colors={availableColors} />
          )}
        </InfoContainer>

        {nowPrice.price !== 0 && (
          <PriceWrapper>
            <Price
              friendlyWasPrice={wasPrice?.priceRoundedAsString}
              friendlyNowPrice={nowPrice?.priceRoundedAsString}
              wasPrice={wasPrice.price}
              nowPrice={nowPrice.price}
              size="sm"
            />
            {/* {monthlyCost && (
              <PricePerMonth
                friendlyMonthlyPrice={monthlyCost.priceRoundedAsString}
              />
            )} */}
          </PriceWrapper>
        )}
      </Wrapper>
    </Card>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: 0,
  fontSize: '20px',
  fontFamily: '$fontSecondary400',
  wordSpacing: 0,
  lineHeight: '$lh120',
  ls: '$ls0',
  gridColumn: '1',
  gridRow: '1',
  zIndex: '$ProductCardMain',
  flexGrow: 1,
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'grid',
  gridTemplate: '1fr / 1fr',
});

const Card = styled(KexLink, {
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  borderStyle: 'solid',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const ASPECT_RATIO = '296 / 181';

const ProductImage = styled('img', {
  left: 0,
  width: '100%',
  aspectRatio: ASPECT_RATIO,
  objectFit: 'cover',
  transition: 'transform 0.2s ease-in-out',
  opacity: 1,
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.05)',
  },
  variants: {
    hasSecondImage: {
      true: {
        '&:hover': {
          transform: 'none',
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out',
        },
      },
    },
    hasDarkBackgroundImage: {
      true: {
        filter: '$colors$imageFilter',
      },
      false: {
        filter: 'none',
      },
    },
  },
});

const SecondProductImage = styled('img', {
  left: 0,
  width: '100%',
  objectFit: 'cover',
  aspectRatio: ASPECT_RATIO,
  gridColumn: '1',
  gridRow: '1',
  zIndex: '$ProductCardSecondary',
  variants: {
    hasDarkBackgroundImage: {
      true: {
        filter: '$colors$imageFilter',
      },
      false: {
        filter: 'none',
      },
    },
  },
});

const CategoryStyle = styled('div', {
  display: 'flex',
  position: 'relative',
  mt: 4,
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: '$ls1',
  color: '#F0F0f1',
  fs: 6,
});

const ProductHeading = styled('h3', {
  fontFamily: '$fontSecondary400',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0px',
  height: '48.7px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  mt: 2,
  mb: 4,
  textDecoration: 'underline solid transparent',
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  textUnderlineOffset: '2px',
  transitionTimingFunction: 'ease-in-out',
  variants: {
    underlined: {
      true: {
        textDecoration: 'underline solid',
      },
    },
  },
});

const InfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  rowGap: 12,
  flexGrow: 1,
});

const FlexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '25%',
  ':first-child': {
    mb: 1,
  },
});

const InfoText = styled('div', {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  fs: 6,
  letterSpacing: '$ls0',
  lineHeight: '$lh133',
  color: '$secondary2',
});

const ColorContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  mt: 1,
  columnGap: '4px',
});

const PriceWrapper = styled('div', {
  mt: 4,
  mb: 4,
});

const Dot = styled('span', {
  h: 1,
  w: 1,
  borderRadius: '50%',
  backgroundColor: '#eee',
  alignSelf: 'center',
  justifySelf: 'center',
  mx: 2,
});
export default ProductCard;
