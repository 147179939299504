import { styled } from 'stitches.config';
import ProductHexColors from '../../Shared/Common/ProductHexColors';

type PropType = {
  color?: string;
  size?: 's' | 'm';
  noMargin?: boolean;
  noColorfallback?: true;
};

function ColorOption({
  color = 'transparent',
  size = 'm',
  noMargin = false,
  noColorfallback,
}: PropType) {
  function splitColors() {
    if (ProductHexColors[color]?.includes('/')) {
      return ProductHexColors[color]?.split('/');
    }
  }

  const splitColor = splitColors();
  const isHalfCircle = ProductHexColors[color]?.includes('/');

  if (noColorfallback) return <></>;

  return (
    <Circle size={size} noMargin={noMargin}>
      {isHalfCircle ? (
        <>
          <HalfCircleColor css={{ backgroundColor: splitColor?.[0] }} />
          <HalfCircleColor css={{ backgroundColor: splitColor?.[1] }} />
        </>
      ) : (
        <FullCircleColor css={{ backgroundColor: ProductHexColors[color] }} />
      )}
    </Circle>
  );
}

const Circle = styled('div', {
  borderRadius: '50%',
  overflow: 'hidden',
  border: '0.5px solid $primaryLight3',
  variants: {
    noMargin: {
      true: {
        mr: 0,
      },
    },
    size: {
      s: {
        mr: 1,
        w: 3,
        h: 3,
      },
      m: {
        mr: 2,
        w: 4,
        h: 4,
      },
    },
  },
});

const HalfCircleColor = styled('div', {
  height: '50%',
});

const FullCircleColor = styled('div', {
  height: '100%',
});

export default ColorOption;
