import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type PropType = {
  nowPrice: number | undefined;
  wasPrice: number | undefined;
  friendlyNowPrice: string | undefined;
  friendlyWasPrice: string | undefined;
  size?: 'xs' | 'sm' | 'lg';
  direction?: 'row' | 'columnReverse';
  nowPriceSize?: CSS;
  wasPriceSize?: CSS;
};

function Price({
  nowPrice,
  wasPrice,
  friendlyNowPrice,
  friendlyWasPrice,
  size = 'sm',
  direction = 'row',
  nowPriceSize,
  wasPriceSize,
}: PropType) {
  let hasLoweredPrice = false;
  if (nowPrice && wasPrice) {
    hasLoweredPrice = nowPrice < wasPrice;
  }

  return (
    <PriceContainer size={size} direction={direction}>
      <NowPriceText hasLoweredPrice={hasLoweredPrice} css={{ ...nowPriceSize }}>
        {friendlyNowPrice}
      </NowPriceText>
      {hasLoweredPrice && (
        <WasPriceText size={size} css={{ ...wasPriceSize }}>
          {friendlyWasPrice}
        </WasPriceText>
      )}
    </PriceContainer>
  );
}

export default Price;

const PriceContainer = styled('div', {
  display: 'flex',
  variants: {
    direction: {
      columnReverse: {
        flexDirection: 'column-reverse',
        rowGap: 4,
      },
      row: {
        flexDirection: 'row',
      },
    },
    size: {
      xs: {
        fs: 6,
        lineHeight: '$lh133',
        columnGap: 26,
      },
      sm: {
        fs: 8,
        lineHeight: '$lh15',
        columnGap: 32,
      },
      lg: {
        columnGap: 40,
        fs: 12,
        lineHeight: '$lh116',
        fontFamily: '$fontPrimary400',
        wordSpacing: '$wordSpacings$fontPrimary',
        ls: '$ls1',
        flexDirection: 'column',
        '@mediaMinLarge': {
          flexDirection: 'row',
        },
      },
    },
  },
});

const NowPriceText = styled('span', {
  variants: {
    hasLoweredPrice: {
      true: {
        color: '$JE68GreenPrimary',
      },
      false: {
        color: '$secondary2',
      },
    },
  },
});

const WasPriceText = styled('span', {
  textDecoration: 'line-through',
  color: '$secondary2',
  variants: {
    size: {
      lg: {
        // Reason why font size is specified here again is because we have special case where size 'lg' and in MOBILE should show different font size.
        fs: 9,
        lineHeight: '$lh133',
        '@mediaMinLarge': {
          fontSize: 'inherit',
          lineHeight: 'inherit',
        },
      },
      sm: {},
      xs: {},
    },
  },
});
