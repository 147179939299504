import MultiRangeSlider from 'Atoms/MultiRangeSlider/MultiRangeSlider';
import KexFacet from 'Models/Search/KexFacet.interface';
import MultiSelectDropDown from 'Molecules/DropDowns/MultiSelectDropDown';
import React, { useMemo } from 'react';
import { mapSearchTranslations } from 'Shared/Common/mapTranslation';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import GenericCollapsable from './GenericCollapsable';
import MultiSelectCollapsableContent from './MultiSelectCollapsableContent';
import { useFilterData } from './FilterProvider';

type PropTypes = {
  item: KexFacet;
  query: string;
  isModal?: boolean;
};

function FilterItem({ item: { ranges, terms, name }, isModal }: PropTypes) {
  const { searchLabels } = useTranslationData();
  const [state, dispatch] = useFilterData();

  const MIN = ranges ? ranges[0].min.toString() : '';
  const MAX = ranges ? ranges[0].max.toString() : '';

  const handleMultiChange = (
    e: React.MouseEvent<HTMLLIElement>,
    key: string,
    name: string,
    isChecked: boolean
  ) => {
    e.preventDefault();

    dispatch({
      type: 'setMultiSelectFilter',
      value: key,
      key: name,
      checked: !isChecked,
    });
  };

  const updateRangeValues = (currentMin: string, currentMax: string) =>
    dispatch({
      type: 'setRangeFilter',
      key: name,
      currentMin,
      currentMax,
      min: MIN,
      max: MAX,
    });

  const facetObject = useMemo(
    () =>
      terms?.map((terms) => ({
        value: terms.userFriendlyName,
        key: terms.term,
        count: terms.count,
      })),
    [terms]
  );

  const rangeFilter = state.rangeFilters.get(name);

  return (
    <DropDownWrapper>
      {facetObject ? (
        <>
          {isModal ? (
            <GenericCollapsable
              label={mapSearchTranslations(searchLabels, name)}
            >
              <MultiSelectCollapsableContent
                name={name}
                values={facetObject}
                selectedValues={Array.from(
                  state.multiSelectFilters.get(name) || []
                )}
                placeholderLabel={name}
                handleChange={(
                  e: React.MouseEvent<HTMLLIElement>,
                  key: string,
                  name: string,
                  isChecked: boolean
                ) => handleMultiChange(e, key, name, isChecked)}
              ></MultiSelectCollapsableContent>
            </GenericCollapsable>
          ) : (
            <DropDownContainer>
              <MultiSelectDropDown
                name={name}
                values={facetObject}
                selectedValues={Array.from(
                  state.multiSelectFilters.get(name) || []
                )}
                placeholderLabel={mapSearchTranslations(searchLabels, name)}
                handleChange={(
                  e: React.MouseEvent<HTMLLIElement>,
                  key: string,
                  name: string,
                  value: string,
                  isChecked: boolean
                ) => handleMultiChange(e, key, name, isChecked)}
              />
            </DropDownContainer>
          )}
        </>
      ) : (
        <>
          {rangeFilter && (
            <GenericCollapsable
              label={mapSearchTranslations(searchLabels, name)}
              inRow={!isModal}
            >
              <MultiRangeSlider
                key={JSON.stringify({
                  name,
                  min: ranges[0].min,
                  max: ranges[0].max,
                })}
                min={ranges[0].min}
                max={ranges[0].max}
                unit={mapSearchTranslations(searchLabels, name, true)}
                outerFilter={{
                  min: Number.parseInt(rangeFilter.currentMin),
                  max: Number.parseInt(rangeFilter.currentMax),
                }}
                onChange={(currentMin: number, currentMax: number) =>
                  updateRangeValues(
                    currentMin.toString(),
                    currentMax.toString()
                  )
                }
              />
            </GenericCollapsable>
          )}
        </>
      )}
    </DropDownWrapper>
  );
}

export default FilterItem;

const DropDownWrapper = styled('div', {
  display: 'flex',
});

const DropDownContainer = styled('div', {
  mr: 4,
});
