import KexIconLoader from 'Kex/KexIconLoader';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';
import { CSS } from '@stitches/react';

type PropType = {
  isChecked: boolean;
  onCheck?: React.ChangeEventHandler<HTMLInputElement>;
  text: string;
  id?: string;
  css?: CSS;
  error?: boolean;
  noMargin?: true;
};

function Checkbox({
  isChecked,
  onCheck,
  id,
  text,
  css,
  error,
  noMargin,
}: PropType) {
  const Icon = KexIconLoader('Checkmark');
  return (
    <CheckboxWrapper css={css}>
      <Label>
        <Input
          type="checkbox"
          id={id}
          checked={isChecked}
          onChange={onCheck}
          tabIndex={-1}
        />
        <Check
          isChecked={isChecked}
          error={!isChecked && error}
          noMargin={noMargin}
        >
          <Icon
            css={StyledIcon}
            size="xs"
            isChecked={isChecked}
            color="secondaryDark"
            onBackground={true}
          />
        </Check>
        {text}
      </Label>
    </CheckboxWrapper>
  );
}

export default Checkbox;

const CheckboxWrapper = styled('div', {});

const StyledIcon = {
  m: 0,
};

const Input = styled('input', {
  opacity: 0,
  wh: 0,
});

const Label = styled('label', {
  cursor: 'pointer',
  color: '$secondary2',
  display: 'flex',
  alignItems: 'center',
  fs: 8,
  lineHeight: '$lh15',
});

const Check = styled('div', {
  cursor: 'pointer',
  mr: 4,
  tblr: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: 'inherit',
  wh: 4,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  variants: {
    isChecked: {
      true: {
        borderColor: '$JE68GreenPrimary',
        backgroundColor: '$JE68GreenPrimary',
      },
      false: {
        borderColor: '$secondary2',
      },
    },
    noMargin: {
      true: {
        m: 0,
      },
    },
    error: {
      true: {
        borderColor: 'red',
      },
    },
  },
});
